import DauMetric from "./DauMetric";
import { authRoles } from "app/auth";

export const MetricsConfig = {
  auth: [...authRoles.admin],
  routes: [
    {
      path: "/metrics/dau",
      component: DauMetric,
      name: "DAU Metric",
    },
  ],
};
