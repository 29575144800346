import React, { Component } from "react";
import { Spinner, Table } from "reactstrap";

import PropTypes from "prop-types";
import _ from "lodash";

const propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
};

class ProfitUnits extends Component {
  render() {
    const { data, loading, groupBy } = this.props;

    const keys = _.chain(data).first().keys().without("_id").value();

    return (
      <>
        {loading === true ? (
          <Spinner color="primary" size="md" />
        ) : (
          <Table responsive size="sm" style={{ textAlign: "right" }}>
            <thead>
              <tr>
                <th width="120px" style={{ textAlign: "left" }}>
                  {groupBy === 1 ? "Date" : ""}
                </th>
                {keys.map((k) => (
                  <th width="80px">
                    <span
                      style={{
                        writingMode: "vertical-lr",
                        // transform: "rotate(-90deg)",
                        // transformOrigin: "bottom right",
                      }}
                    >
                      {k + " ($)"}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row) => {
                return (
                  <tr key={`${row._id}`}>
                    <td rowSpan={keys + 1} style={{ textAlign: "left" }}>
                      <b>{row._id}</b>
                    </td>
                    {keys.map((k) => (
                      <td
                        style={
                          _.startsWith(row._id, "TOTAL")
                            ? { fontWeight: "bold" }
                            : {}
                        }
                      >
                        {row[k]}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </>
    );
  }
}

ProfitUnits.propTypes = propTypes;

export default ProfitUnits;
