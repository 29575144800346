import "./react_dates_overrides.css";

import * as Actions from "./store/actions";

import {
  ButtonGroup,
  ButtonToggle,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import React, { Component } from "react";

import { DateRangePicker } from "react-dates";
import { TapchampsUtils } from "@tapchamps";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";
import ProfitUnitsTable from "app/views/Widgets/ProfitUnits";

const GROUP_BY = {
  DATE: 1,
};

class ProfitUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupBy: 1,
      focusedInput: null,
      startDate: null,
      endDate: null,
    };

    this.onGroupByChange = this.onGroupByChange.bind(this);
  }
  componentDidMount() {
    const {
      profit: { startDate, endDate },
    } = this.props;
    this.setState({ startDate, endDate }, () => {
      this.props.dispatch(Actions.getProfitUnits());
    });
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  onGroupByChange(groupBy) {
    this.setState({ groupBy });
    this.props.dispatch(Actions.getProfitUnits(groupBy));
  }

  render() {
    const { loading } = this.props;
    if (loading) {
      return loading();
    }

    const canView = TapchampsUtils.hasPermission(
      ["profit-viewer"],
      this.props.user.role
    );

    const {
      profit: { units: profit_data, loading: profit_loading },
    } = this.props;

    return (
      canView && (
        <div className="animated fadeIn">
          <Row>
            <Col>
              <ButtonGroup className="float-left mb-2">
                <ButtonToggle
                  active={this.state.groupBy === GROUP_BY.DATE}
                  onClick={(e) => {
                    this.onGroupByChange(GROUP_BY.DATE);
                  }}
                >
                  Date
                </ButtonToggle>
              </ButtonGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  {"By Date"}
                  <FormGroup className="float-right mb-0">
                    <DateRangePicker
                      isOutsideRange={() => false}
                      startDate={this.state.startDate}
                      startDateId="startDate"
                      endDate={this.state.endDate}
                      endDateId="endDate"
                      small
                      minimumNights={0}
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({ startDate, endDate })
                      }
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => {
                        this.setState({ focusedInput });
                        if (focusedInput === null) {
                          setTimeout(() => {
                            this.props.dispatch(
                              Actions.setDateRangeUnits(
                                this.state.groupBy,
                                this.state.startDate,
                                this.state.endDate
                              )
                            );
                          }, 500);
                        }
                      }}
                      orientation={this.state.orientation}
                      openDirection={this.state.openDirection}
                    />
                  </FormGroup>
                </CardHeader>
                <CardBody>
                  <ProfitUnitsTable
                    loading={profit_loading}
                    data={profit_data}
                    groupBy={this.state.groupBy}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    profit: state.profit.profit,
    user: state.auth.user,
  };
};

export default withReducer(
  "profit",
  reducer
)(connect(mapStateToProps)(ProfitUnits));
