import agebuckets from "app/containers/BreakdownStats/store/sagas";
import { fork } from "redux-saga/effects";
import gamestats from "app/containers/GameStats/store/sagas";
import gameinstallstats from "app/containers/GameInstallStats/store/sagas";
import upliftgamesstats from "app/containers/UpliftGamesStats/store/sagas";
import revenues from "app/containers/Revenues/store/sagas";
import profit from "app/containers/Profit/store/sagas";
import metrics from "app/containers/Metrics/store/sagas";
import tables from "./tables.saga";

export default function* rootSaga() {
  yield fork(tables);
  yield fork(agebuckets);
  yield fork(metrics);
  yield fork(gamestats);
  yield fork(gameinstallstats);
  yield fork(upliftgamesstats);
  yield fork(revenues);
  yield fork(profit);
}
