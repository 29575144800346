import "react-bootstrap-table/dist//react-bootstrap-table-all.min.css";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, Spinner } from "reactstrap";
import React, { useRef } from "react";

import { Link } from "react-router-dom";
import moment from "moment";

function numberFormat(col) {
  return col === undefined ? 0 : col;
}

function dollarFormat(col) {
  return col === undefined ? "$0" : `$${col}`;
}

function dateFormat(col, row) {
  return moment(col).format("lll");
}

function minuteFormat(col) {
  if (col > 0) {
    return Math.round((col / 60) * 100) / 100;
  }
  return 0;
}

const MarkedTable = ({
  onSearchChange,
  onFilterChange,
  onClearAllFilters,
  onSortChange,
  onPageChange,
  onSizePerPageList,
  data,
  loading,
  totalDataSize,
  sizePerPage,
  currentPage,
}) => {
  const columnRefs = useRef({});

  let isClearingAllFilters = false;

  //
  // actions
  //
  const handlerClickCleanFiltered = (e) => {
    isClearingAllFilters = true;
    columnRefs.current.user_username.cleanFiltered();
    columnRefs.current.user_countrycode.cleanFiltered();
    columnRefs.current.user_created_at.cleanFiltered();
    columnRefs.current.user_last_seen.cleanFiltered();
    columnRefs.current.user_units.cleanFiltered();
    columnRefs.current.user_timePlayed.cleanFiltered();
    columnRefs.current.user_total_games_downloaded.cleanFiltered();
    columnRefs.current.user_revenue_generated.cleanFiltered();
    columnRefs.current.user_cost_of_rewards_recieved.cleanFiltered();
    columnRefs.current.device_resolution.cleanFiltered();
    columnRefs.current.device_app_version_name.cleanFiltered();
    columnRefs.current.device_osversion.cleanFiltered();
    columnRefs.current.device_gaid.cleanFiltered();
    columnRefs.current.reason.cleanFiltered();
    columnRefs.current.note.cleanFiltered();
    columnRefs.current.mark_created_at.cleanFiltered();
    columnRefs.current.device_hw.cleanFiltered();
    columnRefs.current.device_model.cleanFiltered();
    columnRefs.current.user_ipaddr.cleanFiltered();
    onClearAllFilters();

    isClearingAllFilters = false;
  };
  const setJoinFilter = (day, days = false) => {
    if (days === "yesterday") {
      columnRefs.current.mark_create_at.applyFilter({
        date: moment().subtract(1, "d").toDate(),
        comparator: "=",
      });
    } else if (days === "today") {
      columnRefs.current.mark_create_at.applyFilter({
        date: new Date(),
        comparator: "=",
      });
    } else {
      columnRefs.current.mark_create_at.applyFilter({
        date: moment().subtract(day, "d").startOf("day").toDate(),
        comparator: ">=",
      });
    }
  };

  const onChangeFilter = (filter) => {
    if (!isClearingAllFilters) {
      onFilterChange(filter);
    }
  };

  const buttonFormatter = (cell, row) => {
    return <Link to={"/users/" + row.acc_id}>{row.user_username}</Link>;
  };
  return (
    <>
      <Button
        onClick={handlerClickCleanFiltered}
        size="sm"
        outline
        className="mb-3 mr-3"
        color="info"
      >
        clear filters
      </Button>
      <Button
        onClick={() => setJoinFilter(false, "today")}
        size="sm"
        color="primary"
        outline
        className="mb-1 ml-3"
      >
        Today
      </Button>
      <Button
        onClick={() => setJoinFilter(false, "yesterday")}
        size="sm"
        color="primary"
        outline
        className="mb-1 ml-3"
      >
        Yesterday
      </Button>
      <Button
        onClick={() => setJoinFilter(2)}
        size="sm"
        color="primary"
        outline
        className="mb-1 ml-3"
      >
        Last 2 days
      </Button>
      <Button
        onClick={() => setJoinFilter(7)}
        size="sm"
        color="primary"
        outline
        className="mb-1 ml-3"
      >
        Last 7 days
      </Button>
      {loading === true && (
        <Spinner className="ml-2" color="primary" size="sm" />
      )}

      <BootstrapTable
        trClassName="customBootstrapTable"
        data={loading ? [] : data}
        remote={true}
        pagination={true}
        striped
        fetchInfo={{ dataTotalSize: totalDataSize }}
        options={{
          defaultSortName: "mark_created_at",
          defaultSortOrder: "desc",
          onSearchChange: onSearchChange,
          clearSearch: true,
          onFilterChange: onChangeFilter,
          onSortChange: onSortChange,
          sizePerPage: sizePerPage,
          onPageChange: onPageChange,
          sizePerPageList: [10, 20, 50, 100],
          page: currentPage,
          onSizePerPageList: onSizePerPageList,
        }}
      >
        <TableHeaderColumn dataField="acc_id" isKey hidden>
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.user_username = el)}
          width="200px"
          dataField="user_username"
          dataSort={true}
          dataFormat={buttonFormatter}
          filter={{ type: "TextFilter" }}
        >
          Username
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.email = el)}
          width="200px"
          dataField="user_email"
          dataSort={true}
          filter={{ type: "TextFilter" }}
        >
          Email
        </TableHeaderColumn>

        <TableHeaderColumn
          ref={(el) => (columnRefs.current.mark_created_at = el)}
          width="250px"
          dataField="mark_created_at"
          dataFormat={dateFormat}
          filter={{ type: "DateFilter" }}
          dataSort={true}
        >
          Mark Created At
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.reason = el)}
          width="300px"
          dataSort={false}
          dataField="reason"
          filter={{ type: "TextFilter" }}
        >
          Reason
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.note = el)}
          width="400px"
          dataSort={false}
          dataField="note"
          filter={{ type: "TextFilter" }}
        >
          Note
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.user_countrycode = el)}
          width="150px"
          dataField="user_countrycode"
          dataSort={true}
          filter={{ type: "TextFilter" }}
        >
          countrycode
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.user_created_at = el)}
          width="250px"
          dataField="user_created_at"
          dataFormat={dateFormat}
          filter={{ type: "DateFilter" }}
          dataSort={true}
        >
          Join Date
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.user_last_seen = el)}
          width="250px"
          dataField="user_last_seen"
          dataFormat={dateFormat}
          filter={{ type: "DateFilter" }}
          dataSort={true}
        >
          Last Seen Date
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.user_units = el)}
          width="200px"
          dataField="user_units"
          dataSort={true}
          filter={{
            type: "NumberFilter",
            delay: 1000,
            numberComparators: ["=", ">", "<="],
          }}
        >
          Units
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.user_timePlayed = el)}
          width="220px"
          filter={{
            type: "NumberFilter",
            delay: 1000,
            numberComparators: ["=", ">", "<="],
          }}
          dataField="user_timePlayed"
          dataFormat={minuteFormat}
          dataSort={true}
        >
          Minutes Played
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.user_total_games_downloaded = el)}
          filter={{
            type: "NumberFilter",
            delay: 1000,
            numberComparators: ["=", ">", "<="],
          }}
          width="220px"
          dataField="user_total_games_downloaded"
          dataFormat={numberFormat}
          dataSort={true}
        >
          Total Games Downloaded
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.user_revenue_generated = el)}
          filter={{
            type: "NumberFilter",
            delay: 1000,
            numberComparators: ["=", ">", "<="],
          }}
          width="220px"
          dataField="user_revenue_generated"
          dataFormat={dollarFormat}
          dataSort={true}
        >
          Revenue Generated
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.user_cost_of_rewards_recieved = el)}
          filter={{
            type: "NumberFilter",
            delay: 1000,
            numberComparators: ["=", ">", "<="],
          }}
          width="220px"
          dataField="user_cost_of_rewards_recieved"
          dataFormat={dollarFormat}
          dataSort={true}
        >
          Cost of Rewards Recieved
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.device_resolution = el)}
          width="220px"
          dataSort={false}
          dataField="device_resolution"
        >
          Resolution
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.user_ipaddr = el)}
          width="200px"
          dataSort={false}
          dataField="user_ipaddr"
          filter={{ type: "TextFilter" }}
        >
          IP
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.device_app_version_name = el)}
          width="220px"
          dataSort={false}
          dataField="device_app_version_name"
          filter={{ type: "TextFilter" }}
        >
          App Version
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.device_osversion = el)}
          width="120px"
          dataSort={false}
          dataField="device_osversion"
          filter={{ type: "TextFilter" }}
        >
          Os Version
        </TableHeaderColumn>

        <TableHeaderColumn
          ref={(el) => (columnRefs.current.device_gaid = el)}
          width="320px"
          dataSort={false}
          dataField="device_gaid"
          filter={{ type: "TextFilter" }}
        >
          Device Gaid
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.device_hw = el)}
          width="200px"
          dataField="device_hw"
          dataSort={true}
          filter={{
            type: "TextFilter",
            delay: 1000,
          }}
        >
          Device HW
        </TableHeaderColumn>
        <TableHeaderColumn
          ref={(el) => (columnRefs.current.device_model = el)}
          width="200px"
          dataField="device_model"
          dataSort={true}
          filter={{ type: "TextFilter" }}
        >
          Device Model
        </TableHeaderColumn>
      </BootstrapTable>
    </>
  );
};

export default MarkedTable;
