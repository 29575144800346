import "./react_dates_overrides.css";

import * as Actions from "./store/actions";

import {
  ButtonGroup,
  ButtonToggle,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import { DateRangePicker } from "react-dates";
import _ from "lodash";
import { connect } from "react-redux";
import reducer from "./store/reducers";
import withReducer from "app/store/withReducer";

const USERS_AGE = {
  ALL: 0,
  D1: 1,
  D3: 2,
  D7: 3,
  D14: 4,
  D30: 5,
  D90: 6,
  D180: 7,
  D365: 8,
  Custom: 9,
};

class DauMetric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAgeType: 0,
      focusedInput: null,
      focusedInputAge: null,
      startDate: null,
      endDate: null,
      startDateAge: null,
      endDateAge: null,
      sizePerPage: 10,
    };

    this.onUserAgeTypeChange = this.onUserAgeTypeChange.bind(this);
  }
  componentDidMount() {
    const {
      metrics: { startDate, endDate },
    } = this.props;
    this.setState({ startDate, endDate }, () => {
      this.props.dispatch(Actions.getDauMetrics());
    });
  }
  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  onUserAgeTypeChange(userAgeType) {
    this.setState({ userAgeType });
    if (userAgeType !== USERS_AGE.Custom) {
      this.props.dispatch(Actions.getDauMetrics(userAgeType));
    }
  }

  onSizePerPageList(sizePerPage) {
    this.setState({ sizePerPage: sizePerPage });
  }

  priceFormatter = (cell, row) => {
    return `$ ${cell?.toFixed(2)}`;
  };

  render() {
    const { loading } = this.props;
    if (loading) {
      return loading();
    }

    let {
      metrics: { data: metric_data, loading: metric_loading },
    } = this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col>
            <ButtonGroup className="float-left mb-2">
              {_.entries(USERS_AGE).map(([key, val]) => {
                return (
                  <ButtonToggle
                    key={key}
                    active={this.state.userAgeType === val}
                    onClick={(e) => {
                      this.onUserAgeTypeChange(val);
                    }}
                  >
                    {key}
                  </ButtonToggle>
                );
              })}
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                {this.state.userAgeType === USERS_AGE.Custom && (
                  <FormGroup className="float-left mb-0">
                    <DateRangePicker
                      minimumNights={0}
                      isOutsideRange={() => false}
                      startDate={this.state.startDateAge}
                      startDateId="startDateAge"
                      startDatePlaceholderText="Start Age"
                      endDatePlaceholderText="End Age"
                      endDate={this.state.endDateAge}
                      endDateId="endDateAgeAge"
                      small
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({
                          startDateAge: startDate,
                          endDateAge: endDate,
                        })
                      }
                      focusedInput={this.state.focusedInputAge}
                      onFocusChange={(focusedInputAge) => {
                        this.setState({ focusedInputAge });
                        if (focusedInputAge === null) {
                          setTimeout(() => {
                            this.props.dispatch(
                              Actions.setDateRange(
                                this.state.userAgeType,
                                this.state.startDate,
                                this.state.endDate,
                                this.state.startDateAge,
                                this.state.endDateAge
                              )
                            );
                          }, 500);
                        }
                      }}
                      orientation={this.state.orientation}
                      openDirection={this.state.openDirection}
                    />
                  </FormGroup>
                )}
                <FormGroup className="float-right mb-0">
                  <DateRangePicker
                    minimumNights={0}
                    isOutsideRange={() => false}
                    startDate={this.state.startDate}
                    startDateId="startDate"
                    endDate={this.state.endDate}
                    endDateId="endDate"
                    small
                    onDatesChange={({ startDate, endDate }) =>
                      this.setState({ startDate, endDate })
                    }
                    focusedInput={this.state.focusedInput}
                    onFocusChange={(focusedInput) => {
                      this.setState({ focusedInput });
                      if (focusedInput === null) {
                        setTimeout(() => {
                          this.props.dispatch(
                            Actions.setDateRange(
                              this.state.userAgeType,
                              this.state.startDate,
                              this.state.endDate,
                              this.state.startDateAge,
                              this.state.endDateAge
                            )
                          );
                        }, 500);
                      }
                    }}
                    orientation={this.state.orientation}
                    openDirection={this.state.openDirection}
                  />
                </FormGroup>
              </CardHeader>
              <CardBody>
                {metric_loading ? (
                  <div className="text-center">Loading data</div>
                ) : metric_data.length === 0 ? (
                  <div className="text-center">Empty</div>
                ) : (
                  <BootstrapTable
                    trClassName="customBootstrapTable"
                    data={metric_loading ? [] : metric_data}
                    remote={false}
                    pagination={true}
                    striped
                    options={{
                      defaultSortName: "created_at_date",
                      defaultSortOrder: "desc",
                      clearSearch: true,
                      sizePerPageList: [10, 20, 50, 100],
                      sizePerPage: this.state.sizePerPage,
                      onSizePerPageList: this.onSizePerPageList.bind(this),
                    }}
                  >
                    <TableHeaderColumn
                      dataField={"created_at_date"}
                      isKey
                      hidden
                    >
                      ID
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      ref="created_at_date"
                      dataField="created_at_date"
                      dataSort={true}
                    >
                      Date
                    </TableHeaderColumn>

                    <TableHeaderColumn
                      ref="dau"
                      dataField="dau"
                      dataSort={true}
                    >
                      DAU
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="revenue"
                      dataField="revenue"
                      dataFormat={this.priceFormatter}
                      dataSort={true}
                    >
                      Revenue ($)
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="unitsUsd"
                      dataField="unitsUsd"
                      dataFormat={this.priceFormatter}
                      dataSort={true}
                    >
                      Units ($)
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="revenuePerUser"
                      dataField="revenuePerUser"
                      dataFormat={this.priceFormatter}
                      dataSort={true}
                    >
                      Revenue/User ($)
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="unitsPerUser"
                      dataField="unitsPerUser"
                      dataSort={true}
                    >
                      Units/User
                    </TableHeaderColumn>
                    <TableHeaderColumn
                      ref="unitsPerUserUsd"
                      dataField="unitsPerUserUsd"
                      dataFormat={this.priceFormatter}
                      dataSort={true}
                    >
                      Units/User ($)
                    </TableHeaderColumn>
                  </BootstrapTable>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    metrics: state.metrics.metrics,
    user: state.auth.user,
  };
};

export default withReducer(
  "metrics",
  reducer
)(connect(mapStateToProps)(DauMetric));
