import GameInstallStats from "./GameInstallStats";
import { authRoles } from "app/auth";

export const GameInstallStatsConfig = {
  auth: [...authRoles.admin],
  routes: [
    {
      path: "/gameinstallstats",
      component: GameInstallStats,
      name: "Game Install Stats",
    },
  ],
};
