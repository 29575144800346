import { apiSagaRequest, showMessage } from "app/store/actions/tapchamps";

import axios from "axios";

export const GET_PROFIT = "[PROFIT] GET PROFIT";
export const GET_PROFIT_UNITS = "[PROFIT] GET PROFIT_UNITS";
export const SET_AVG_UNITS = "[PROFIT] SET AVG UNITS";
export const SET_LOADING = "[PROFIT] SET LOADING";
export const GET_MEDIA_FOR_SELECT = "[PROFIT] GET MEDIA FOR SELECT";
export const SET_DATERANGE = "[PROFIT] SET DATERANGE";
export const SET_DATERANGE_UNITS = "[PROFIT] SET DATERANGE_UNITS";

export function setDateRange(mediasource, groupby, startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: SET_DATERANGE,
      payload: { startDate, endDate },
    });
    dispatch(getProfit(mediasource, groupby));
  };
}

export function setDateRangeUnits(groupby, startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: SET_DATERANGE,
      payload: { startDate, endDate },
    });
    dispatch(getProfitUnits(groupby));
  };
}

export function setDateRangeAvgUnits(startDate, endDate) {
  return (dispatch) => {
    dispatch({
      type: SET_DATERANGE,
      payload: { startDate, endDate },
    });
    dispatch(getAverageUnits());
  };
}

export function getMediaForSelect() {
  return (dispatch, getState) => {
    const request = axios.get("/api/profit/forselect", {
      params: {
        sort: "name",
      },
    });
    request.then((response) => {
      dispatch({
        type: GET_MEDIA_FOR_SELECT,
        payload: response.data,
      });
    });
  };
}

export function setLoading(loading) {
  return (dispatch) => {
    dispatch({
      type: SET_LOADING,
      payload: loading,
    });
  };
}

export function getProfit(mediasource, groupby) {
  return (dispatch, getState) => {
    const {
      profit: {
        profit: { startDate, endDate },
      },
    } = getState();

    if (startDate === null || endDate === null) {
      return;
    }
    const dates = {
      startDate: startDate.utc().startOf("day").unix(),
      endDate: endDate.utc().startOf("day").unix(),
    };
    const params = { ...dates };
    if (mediasource !== undefined && mediasource !== "") {
      params.mediasource = mediasource;
    }
    if (groupby !== undefined && groupby !== "") {
      params.groupby = groupby;
    }

    dispatch(setLoading(true));
    dispatch(
      apiSagaRequest("API_PROFIT_REQUEST", {
        params,
        method: "get",
        url:
          groupby === 1
            ? "/api/profit/bydate"
            : groupby === 2
            ? "/api/profit/bycountry"
            : "/api/profit/mediasource",
      })
    ).then(
      (response) => {
        if (response.data.error) {
          dispatch(
            showMessage({ message: response.data.error, type: "error" })
          );
          return;
        }
        dispatch({
          type: GET_PROFIT,
          payload: response.data,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function getProfitUnits(groupby) {
  return (dispatch, getState) => {
    const {
      profit: {
        profit: { startDate, endDate },
      },
    } = getState();
    if (startDate === null || endDate === null) {
      return;
    }
    const dates = {
      startDate: startDate.utc().startOf("day").unix(),
      endDate: endDate.utc().startOf("day").unix(),
    };
    const params = { ...dates };

    if (groupby !== undefined && groupby !== "") {
      params.groupby = groupby;
    }

    dispatch(setLoading(true));
    dispatch(
      apiSagaRequest("API_PROFIT_UNITS_REQUEST", {
        params,
        method: "get",
        url:
          groupby === 1
            ? "/api/profit/units/bydate"
            : "/api/profit/units/bydate",
      })
    ).then(
      (response) => {
        if (response.data.error) {
          dispatch(
            showMessage({ message: response.data.error, type: "error" })
          );
          return;
        }
        console.log(response);
        dispatch({
          type: GET_PROFIT_UNITS,
          payload: response.data,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}

export function getAverageUnits() {
  return (dispatch, getState) => {
    const {
      profit: {
        profit: { startDate, endDate },
      },
    } = getState();

    if (startDate === null || endDate === null) {
      return;
    }
    const dates = {
      startDate: startDate.utc().startOf("day").unix(),
      endDate: endDate.utc().startOf("day").unix(),
    };
    const params = { ...dates };

    dispatch(setLoading(true));
    dispatch(
      apiSagaRequest("API_AVG_UNITS_REQUEST", {
        params,
        method: "get",
        url: "/api/profit/average-units",
      })
    ).then(
      (response) => {
        if (response.data.error) {
          dispatch(
            showMessage({ message: response.data.error, type: "error" })
          );
          return;
        }

        dispatch({
          type: SET_AVG_UNITS,
          payload: response.data,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };
}
